<template>
  <div class="">
    <PreLoader v-if="isLoading" />

    <form autocomplete="off">
      <div class="my-card">
        <div class="row">
          <CustomSelectBoxWithImg
            :className="'col-md-12'"
            :id="'brandToken'"
            :value="brandUser.brandToken"
            :options="brandTokenOptions"
            v-on:changeValue="brandUser.brandToken = $event"
            :title="$t('brands.select')"
            :imgName="'brand.svg'"
          />

          <CustomSelectBoxWithImgMultiple
            :className="'col-md-12'"
            :id="'brandToken'"
            :value="brandUser.usersTokensList"
            :options="userTokenOptions"
            v-on:changeValue="brandUser.usersTokensList = $event"
            :title="$t('users.selectMultiple')"
            :imgName="'users.svg'"
          />
        </div>
      </div>

      <div class="form-footer">
        <button
          name="submit"
          type="submit"
          class="btn btn-submit"
          @click.prevent="addOrUpdateBrandUser"
        >
          {{ submitName }}
        </button>
        <router-link :to="{ name: 'BrandUsers' }" class="btn btn-cancel">
          {{ $t("cancel") }}
        </router-link>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PreLoader from "./../../components/general/PreLoader.vue";
import { USER_TYPE } from "./../../utils/constantLists";
import CustomSelectBoxWithImg from "./../../components/general/CustomSelectBoxWithImg.vue";
import CustomSelectBoxWithImgMultiple from "./../../components/general/CustomSelectBoxWithImgMultiple.vue";
// import CustomCheckbox from "./../../components/general/CustomCheckbox.vue";
import Brands from "./../../models/brands/Brands";
import Users from "./../../models/users/Users";
import createToastMixin from "./../../utils/create-toast-mixin";

export default {
  mixins: [createToastMixin],
  components: {
    PreLoader,
    CustomSelectBoxWithImg,
    CustomSelectBoxWithImgMultiple,
    // CustomCheckbox,
  },
  computed: {
    ...mapGetters(["userAuthorizeToken"]),
  },
  data() {
    return {
      isLoading: true,
      exceptionMsg: null,
      exceptionImg: null,
      language: localStorage.getItem("userLanguage") || "ar",
      imageSrc: "",
      brands: new Brands(),
      brandTokenOptions: [],
      users: new Users(),
      userTokenOptions: [],
    };
  },
  props: ["brandUser", "submitName"],
  methods: {
    async addOrUpdateBrandUser() {
      this.$emit("addOrUpdateBrandUser");
    },
    async getBrandDialog() {
      this.isLoading = true;
      try {
        this.brandTokenOptions = await this.brands.brand.getBrandDialog(
          this.language,
          this.userAuthorizeToken
        );
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
    async getUsersDialog() {
      this.isLoading = true;
      try {
        this.users.filterData.userTypeToken = USER_TYPE.Seller;
        this.userTokenOptions = await this.users.user.getUsersDialog(
          this.language,
          this.userAuthorizeToken,
          this.users.filterData
        );
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
  },
  async created() {
    this.getBrandDialog();
    this.getUsersDialog();
  },
};
</script>

<style lang="scss"></style>
