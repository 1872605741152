<template>
  <div
    class="select-box-with-img select-box-with-img-multiple"
    :class="className"
  >
    <span
      v-if="isSync"
      class="sync-icon"
      @click="sync()"
      :class="{ rotate: isClicked }"
      :title="$t('synchronization')"
    >
      <img class="sync-image" :src="require('@/assets/images/sync.svg')" />
    </span>

    <multiselect
      v-model="theValue"
      :placeholder="title ? title : $t('notFound')"
      label="text"
      track-by="value"
      :options="options"
      :option-height="104"
      :show-labels="true"
      :selectedLabel="$t('selectBox.selectedLabel')"
      :selectLabel="$t('selectBox.selectLabel')"
      :deselectLabel="$t('selectBox.deselectLabel')"
      :multiple="true"
      :close-on-select="false"
    >
      <template slot="singleLabel" slot-scope="props">
        <div class="image"></div>
        <label class="label" :for="id">{{
          title ? title : $t("notFound")
        }}</label>
        <div class="select-box-container">
          <img
            class="option__image"
            :src="props.option.image"
            :onerror="`this.src='${require('@/assets/images/' + imgName)}'`"
          />
          <span class="option__desc">
            <span class="option__title">{{ props.option.text }}</span>
          </span>
        </div>
      </template>
      <template slot="option" slot-scope="props"
        ><img
          class="option__image"
          :src="props.option.image"
          :onerror="`this.src='${require('@/assets/images/' + imgName)}'`"
        />
        <div class="option__desc">
          <span class="option__title">{{ props.option.text }}</span>
        </div>
      </template>
    </multiselect>

    <img
      class="placeholder-image"
      :src="require('@/assets/images/' + imgName)"
    />
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";

export default {
  name: "CustomSelectBoxWithImg",
  components: {
    Multiselect,
  },
  data() {
    return {
      theValue: [],
      isClicked: false,
    };
  },
  methods: {
    getCurrentItems(val) {
      setTimeout(() => {
        let index = this.options.findIndex((x) => x.value == val);

        if (index > -1) {
          this.theValue = {
            value: this.options[index].value,
            text: this.options[index].text,
            image: this.options[index].image,
          };
        } else {
          this.theValue = [];
          // this.$emit("changeValue", "");
        }
      }, 500);
    },
    sync() {
      this.isClicked = true;
      this.$emit("synchronization", "");
    },
  },
  props: {
    className: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "textarea",
    },
    isSync: {
      default: false,
    },
    value: {
      type: Array,
      default: null,
    },
    title: {
      type: String,
      default: "title",
    },
    imgName: {
      type: String,
      default: "man.svg",
    },
    options: {
      type: Array,
    },
  },
  watch: {
    theValue: function (val) {
      this.$emit("changeValue", val ? val : []);
    },
    // value: function (val) {
    //   if (val == []) {
    //     this.getCurrentItems(val);
    //   }
    // },
  },
  async created() {
    // this.getCurrentItems(this.value);
    this.theValue = this.value;
  },
};
</script>
